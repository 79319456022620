 import { tns } from "./../../../node_modules/tiny-slider/src/tiny-slider"

// import Swiper from 'swiper';

export class Sliders {
  async init() {
    // setTimeout(()=> {}, 500);
    
    jQuery(document).ready(function() {
			jQuery('.ee-featured-products').addClass('loaded');
		});

    jQuery( ".ee-featured-products .products .product" ).wrapInner( "<div class='product-container'></div>");

    const tnsCarousel = document.querySelectorAll('.ee-featured-products .products');
    
    tnsCarousel.forEach(slider => { const tnsSlider = tns({
      container: slider,
      slideBy: 1,
      gutter: 20,
      controls: true,
      nav: false,
      // navPosition: 'bottom',
      // navContainer: 'ee_dots',
      loop: false,
      controlsText: ["&#8249;", "&#8250;"],
      autoplay: false,
      items: 1,
      responsive: {
        420: {
          edgePadding: 60,
          gutter: 20,
          items: 1
        },
        510: {
          edgePadding: 90,
          gutter: 20,
          items: 1
        },
        600: {
          edgePadding: 120,
          gutter: 20,
          items: 1
        },
        700: {
          edgePadding: 20,
          gutter: 20,
          items: 2
        },
        1140: {
          edgePadding: 20,
          gutter: 20,
          items: 3
        },
        1280: {
          edgePadding: 20,
          gutter: 20,
          items: 4
        }
      }
      });
    });
    
    /* jQuery Version
      var $tnsCarousel = jQuery('.ee-featured-products .products');
      $tnsCarousel.each(function() {
        console.log(this)
        var $this = this
        var tnsSlider = tns({
          container: $this,
          items: 4,
          slideBy: 1,
          gutter: 30,
          controls: true,
          nav: false,
          loop: false,
          controlsText: ["&#8249;", "&#8250;"],
          autoplay: false
        });
    }); 
    */ 
  }
}